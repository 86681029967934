import "./EmployeeList.css"
import React from "react";
import Section from "../Section/Section"
import EmployeeCard from "../Employee Card/EmployeeCard";
import Pagination from '../UI/Pagination';
import "@egjs/flicking-plugins/dist/pagination.css";
import { SwiperSlide, Swiper } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import { useSelector } from "react-redux";


const EmployeeList = ({ employeeList = [], type, sectionStyle, galleryStyle }) => {
    const language = useSelector((state) => state.meta.language)
    const employeeState = useSelector((state) => state.pages.employee)[language]
    const [isDesktop, setIsDesktop] = React.useState(window.matchMedia('(min-width: 769px)').matches)
    const [isTablet, setIsTablet] = React.useState(window.matchMedia('(min-width: 500px) and (max-width: 768px)').matches)
    const [isMobile, setIsMobile] = React.useState(window.matchMedia('(min-width: 320px) and (max-width: 500px)').matches)
    const [maxCardQuantity, setMaxCardQuantity] = React.useState({
        widget: isDesktop ? 2 : 1,
        gallery: 6,
    })

    const [activeIndex, setActiveIndex] = React.useState(0)

    const sectionTitleVariations = {
        ru: "Наша команда",
        en: "Our team",
        kz: "Біздің команда",
    }

    function handleResize() {
        setTimeout(() => {
            const checkIsDesktop = window.matchMedia('(min-width: 769px)').matches
            const checkIsTablet = window.matchMedia('(min-width: 500px) and (max-width: 768px)').matches
            // const checkIsMobile = window.matchMedia('(min-width: 320px) and (max-width: 500px)').matches

            if (checkIsDesktop !== isDesktop) {
                setIsDesktop(setIsDesktop(checkIsDesktop))
            }
        }, 1)
        window.removeEventListener('resize', handleResize)
        window.addEventListener('resize', handleResize)
    }

    React.useEffect(() => {
        if (type === "widget") {
            if (isDesktop && maxCardQuantity[type] !== 2) {
                setMaxCardQuantity({
                    ...maxCardQuantity,
                    [type]: 2,
                })
            } else if (!isDesktop && maxCardQuantity[type] !== 1) {
                setMaxCardQuantity({
                    ...maxCardQuantity,
                    [type]: 1,
                })
            }
        }
    }, [isDesktop, isTablet, isMobile])

    React.useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    const classVariations = {
        widget: 'employee-list__list_type_widget',
        gallery: 'employee-list__list_type_gallery',
    }

    const testLayout = (
        <>
            {employeeList.map((employee, index) => {
                return (
                    <SwiperSlide key={index} style={{ height: "auto" }}>
                        <div key={index}>
                            <EmployeeCard type={type} employee={employee} index={index} id={employeeState?.indexOf(employee)} />
                        </div>
                    </SwiperSlide>
                )
            })}
        </>
    )

    const widgetListVariation = (
        <Swiper
            onSlideChange={(swiper) => { setActiveIndex(swiper.activeIndex) }}
            modules={[Autoplay]}
            loop={true}
            spaceBetween={50}
            slidesPerView={3}
            width={2000}
            keyboard={true}
            breakpoints={{
                1000: {
                    width: 850,
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                900: {
                    width: 1200,
                },
                800: {
                    width: 730,
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                700: {
                    width: 700,
                    slidesPerView: 2,
                },
                600: {
                    width: 600,
                    slidesPerView: 1,
                },
                500: {
                    width: 450,
                    slidesPerView: 1,
                },
                400: {
                    width: 390,
                    slidesPerView: 1,
                },
                320: {
                    width: 300,
                    slidesPerView: 1,
                }
            }}
        >
            {testLayout}
            <Pagination currentPage={activeIndex} pagesQuantity={employeeList.length} />
        </Swiper>
    )

    const galleryListVariation = (
        <>
            {
                isDesktop
                &&
                <ul className={`employee-list__list ${classVariations[type]}`} style={galleryStyle}>
                    {employeeList.map((employee, index) => {
                        return (
                            <li key={index}>
                                <EmployeeCard type={type} employee={employee} id={index} />
                            </li>
                        )
                    })}
                </ul>
            }

            {
                (isTablet || isMobile)
                &&
                <div>
                    { widgetListVariation }
                </div>
            }
        </>
    )

    const layoutListVariations = {
        widget: widgetListVariation,
        gallery: galleryListVariation,
    }

    return (
        <>
            {
                employeeList.length > 0
                &&
                <Section name={`employee-list-${type}`} type="slider" color="white" style={{ ...sectionStyle }}>
                    <div className="employee-list__title-container">
                        <h2 className="section__title section__title_employee-list">{sectionTitleVariations[language].toUpperCase()}</h2>
                    </div>
                    {layoutListVariations[type]}
                </Section>
            }
        </>
    )
}

export default EmployeeList;
