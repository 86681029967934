import { publicGalleryURL } from "../constants/webConfig";
import { normalizeNews } from "./normalizeNews";

export function normalizeEmployee(employee) {
    if(!employee) return

    return {
        ...employee,
        avatar: employee.avatar && `${publicGalleryURL}${employee.avatar?.data?.attributes?.url}`,
        articles: employee.articles?.data?.map((article) => normalizeNews(article))
    }
}