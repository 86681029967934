import { useSelector } from "react-redux/es/hooks/useSelector"
import { useNavigate, useParams } from "react-router-dom"

import Header from "../Header/Header"
import Promo from "../Promo/Promo"
import EmployeeList from "../Employee List/EmployeeList"
import Block from "../Block/Block"

import ServicesList from "../ServicesList/ServicesList"
import NewsList from "../NewsList/NewsList"
import Footer from "../Footer/Footer"

import { publicGalleryURL } from "../../utils/constants/webConfig"
import JoinUsForm from "../JoinUsForm/JoinUsForm";
import React from "react";

const PracticeAreaInnerPage = () => {
    const navigate = useNavigate()
    let { id } = useParams()

    const language = useSelector((state) => state.meta.language)

    const pagesState = useSelector((state) => state.pages)

    const practiceAreasList = pagesState?.practiceAreas[language]
    const practiceArea = practiceAreasList[id]
    const newsList = practiceArea.news[language]

    const practiceAreasPage = pagesState.practiceAreasPage
    const advBlock = practiceAreasPage[language][0].advBlock

    function handleContacts() {
        navigate("/contact-us")
    }

    function filterEmployees(employeeList, employeeOnPractice) {
        const result = []

        employeeOnPractice.forEach((employee) => {
            const emp = employeeList.find((item) => employee.name === item.name)
            result.push(emp)
        })

        return result
    }

    return (
        <>
            <div style={{ position: "relative", overflow: "hidden" }}>
                <Header page="mainPage" color="gray" />
                <Promo name="main-promo" type="practice-area" color="gray" title={practiceArea.title} subtitle={practiceArea.description} image={practiceArea.promoImage} button="true" style={{ paddingTop: "70px" }} onButton={handleContacts} />
            </div>
            <ServicesList title="" id={id} style={{ marginBottom: "80px" }} />
            <NewsList news={newsList} type="widget" />
            <Block type="advertisement" style={{ marginBottom: "50px" }} innerImage={`${publicGalleryURL}${advBlock.image?.data?.attributes?.url}`} text={[advBlock.text]} button={advBlock.button} buttonText={advBlock.buttonText} />
            <Block type="form" style={{ marginBottom: "100px" }} text={[]}>
                <JoinUsForm />
            </Block>
            <Footer />
        </>
    )
}

export default PracticeAreaInnerPage