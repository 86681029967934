import { publicGalleryURL } from "../constants/webConfig"

export function normalizeContacts(contact) {
    if (!contact)

    console.log(contact);

    return {
        ...contact,
        advBlock: {
            ...contact.advBlock,
            image: `${publicGalleryURL}${contact.advBlock?.image?.data?.attributes?.url}`
        }
    }
}