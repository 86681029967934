import { AnimatePresence, motion } from "framer-motion";
import "./ServiceAccordion.css";
import React, { useState } from 'react';
import dropRightIcon from "../../images/icon-dropleft.svg"
import dropdownIcon from "../../images/icons8-drop-down.svg"
import parseRichText from "../../utils/functions/parseRichText";

function ServiceAccordion({ style, content, index }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className="service-accordion" style={style} >
      <div className="service-accordion__header" onClick={toggleAccordion}>
        {
          content
          &&
          <>
            {/* <p className="service-accordion__numeration">0{index}</p> */}
            {
              isOpen ?
              <div className="service-accordion__icon" style={{ backgroundImage: `url(${dropdownIcon})` }}></div>
              :
              <div className="service-accordion__icon" style={{ backgroundImage: `url(${dropRightIcon})` }}></div>
            }
            <h4 className="service-accordion__title">{content.h2_services}</h4>
          </>
        }
      </div>
      <AnimatePresence initial={false}>
        {
          isOpen
          &&
          (<motion.ul
            className="service-accordion__content"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
          >
            {
              content
              &&
              parseRichText(content.services_detailed, "service-accordion__paragraph")
            }
          </motion.ul>)
        }
      </AnimatePresence>
    </li>
  );
}

export default ServiceAccordion;
