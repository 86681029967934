import React from "react";
import Header from "../Header/Header";
import Promo from "../Promo/Promo";
import PracticeAreasList from "../PraticeAreasList/PracticeAreasList";
import Block from "../Block/Block";
import EmployeeList from "../Employee List/EmployeeList";
import NewsList from "../NewsList/NewsList";
import ContactUs from "../ContactUs/ContactUs";

import { useSelector } from "react-redux";
import Footer from "../Footer/Footer";

const MainPage = () => {
    const meta = useSelector((state) => state.meta)
    const language = meta.language

    const pagesState = useSelector((state) => state.pages)

    const promoState = pagesState.mainPromo[language]
    const practiceAreasList = pagesState.practiceAreas[language]
    const employees = pagesState.employee[language]
    const newsList = pagesState.news[language]
    const contacts = pagesState.contacts[language][0].City

    return (
        <>
            <Header page="mainPage" color="gray" />
            <Promo name="main-promo" type="main" color="gray" title={promoState.title} subtitle={promoState.subtitle} image={promoState.image} button={promoState.button}>
                <ul className="main-promo__list">
                    {
                        promoState.features
                        &&
                        promoState.features.map((feature, index) => (<li className="main-promo__item" key={index}>{feature}</li>))
                    }
                </ul>
            </Promo>
            <PracticeAreasList name="practice-areas-add" title="practice areas" type="additional" practiceAreas={practiceAreasList} style={{ marginTop: '80px' }} />
            <Block type="advertisement" innerImage={promoState.advBlock.image} text={[promoState.advBlock.text]} button={promoState.advBlock.button} buttonText={promoState.advBlock.buttonText} />
            <EmployeeList employeeList={employees} type='widget' />
            <NewsList news={newsList} type="widget" />
            <ContactUs contacts={contacts} />
            <Footer />
        </>
    );
}

export default MainPage;