// Презюмируется, что первый элемент -- список
function parseRichText(array, additionalClass = "", listStyle = "none", style = {}) {
    if (array.length === 0 || !array[0]) return

    function getClassList(textNode, index = null) {
        let classList = ""

        if (textNode.bold) {
            classList += "bold "
        }

        if (textNode.italic) {
            classList += "italic "
        }

        if (textNode.underline) {
            classList += "underline "
        }

        if (textNode.strikethrough) {
            classList += "strikethrough "
        }

        return classList
    }

    return (
        <ul style={{padding: 0, listStyle: listStyle, textAlign: "left",}}>
            {
                array[0].children.map((el, i) =>
                    <li key={i} className={additionalClass} style={style}>
                        {
                            el.children.map((textNode, index) => {
                                if (textNode.type === "text") {
                                    return (
                                        <span key={index} className={getClassList(textNode, index)}
                                              style={style}>{textNode.text}</span>
                                    )
                                } else if (textNode.type === "link") {
                                    return (
                                        <a href={textNode.url} key={index}>
                                            {
                                                textNode.children.map((linkChildren) =>
                                                    <span
                                                        className={getClassList(linkChildren, index)}
                                                        key={index}
                                                        style={style}
                                                    >
                                                        {linkChildren.text}
                                                    </span>
                                                )
                                            }
                                        </a>
                                    )
                                }
                            })
                        }
                    </li>
                )
            }
        </ul>
    )
}

export default parseRichText