import React from "react"
import NewsInner from "../NewsInner/NewsInner"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"

import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"

const NewsInnerPage = () => {
    const { id } = useParams()
    const language = useSelector((state) => state.meta.language)

    const pagesState = useSelector((state) => state.pages)
    const news = pagesState.news[language]
    const thisNews = news[id]

    const employee = pagesState.employee[language]
    const author = employee.find((item) => item.name === thisNews.author.name)

    return (
        <>
            <Header page="mainPage" />
            <NewsInner image={thisNews?.backgroundImage || ""} title={thisNews?.title} content={thisNews.content} readingTime={thisNews.readingTime} author={author} />
            <Footer />
        </>
    )
}

export default NewsInnerPage
