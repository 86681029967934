import "./NewsInner.css"
import ReactMarkdown from "react-markdown"
import {useSelector} from "react-redux";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import React from "react";

const NewsInner = ({image, title, content, author, readingTime}) => {
    const navigate = useNavigate()
    const language = useSelector((store) => store.meta.language)

    const pagesState = useSelector((state) => state.pages)
    const employeeState = useSelector((store) => store.pages.employee)
    const employee = employeeState[language]

    const location = useLocation()
    const practiceAreas = pagesState.practiceAreas[language]

    let id
    employee.forEach((elem, i) => {
        if (elem.name === author.name) id = i
    })

    const mainPageBreadcrumn = {
        ru: "Главная",
        en: "Main page",
        kz: "Негізгі бет",
    }

    const teamPageBreadcrumb = {
        ru: "Команда",
        en: "Team",
        kz: "Команда",
    }

    const newsPageBreadcrumb = {
        ru: "Новости",
        en: "News",
        kz: "Жаңалықтар",
    }


    function getMinutes(minutes) {
        if (minutes === 1) {
            return `${minutes} минута`;
        } else if (/^[2-4]$/.test(minutes)) {
            return `${minutes} минуты`;
        } else if (/^[5-9]$/.test(minutes)) {
            return `${minutes} минут`;
        } else if (/^[1][0-9]$/.test(minutes)) {
            return `${minutes} минут`;
        } else if (/^\d+[1]$/.test(minutes)) {
            return `${minutes} минута`
        } else if (/^\d+[2-4]$/.test(minutes)) {
            return `${minutes} минуты`;
        } else if (/^\d+[5-90]$/.test(minutes)) {
            return `${minutes} минут`;
        }
    }

    const readingTimeVariations = {
        ru: getMinutes(readingTime),
        en: `${readingTime} min read`,
        kz: getMinutes(readingTime),
    }
    function getBreadCrumb() {
        if (location?.state?.prevPath === "/") return <NavLink to={location.state.prevPath} style={{ textDecoration: "none", color: "inherit" }}> {mainPageBreadcrumn[language]} </NavLink>
        if (location?.state?.prevPath === "/team") return <NavLink to={location.state.prevPath} style={{ textDecoration: "none", color: "inherit" }}> {teamPageBreadcrumb[language]} </NavLink>
        if (location?.state?.prevPath === "/news") return <NavLink to={location.state.prevPath} style={{ textDecoration: "none", color: "inherit" }}> {newsPageBreadcrumb[language]} </NavLink>


        if (location?.state?.prevPath?.includes("practice-areas")) {
            const practiceAreaID = location.state.prevPath.replace("/practice-areas/", "")

            return (<NavLink to={location.state.prevPath} style={{ display: "inline-block", textDecoration: "none", color: "inherit", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth:"60%" }}> {practiceAreas[practiceAreaID].title} </NavLink>)
        }
    }

    return (
        <section className="news-inner">
            <div className="bread-crumbs bread-crumbs__team-inner">
                {getBreadCrumb()}
                &nbsp;/&nbsp;<span className="bread-crumbs__after">{title}</span>
            </div>
            {/* <img className="news-inner__image" src={image} alt="Изображение" /> */}
            <article className="article">
                <h2 className="article__title">{title}</h2>

                <div className="article__content">
                    <div className="article__text">
                        <ReactMarkdown>{content}</ReactMarkdown>
                    </div>

                    <div className="article__author" onClick={() => {
                        navigate(`/team/${id || 0}`, {state: {prevPath: location.pathname }})
                    }}>
                        <div className="article__avatar-container">
                            <img className={`article__avatar`} src={author.avatar}
                                 alt={`Portraiot of employee ${author.name}`}/>
                        </div>

                        <div>
                            <p className="article__author-name">{author.name}</p>
                            <p className="article__reading-time">{readingTimeVariations[language]}</p>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    )
}

export default NewsInner
