import "./Navigation.css";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { NavLink } from "react-router-dom";
import LangSwitcher from "../LangSwitcher/LangSwitcher";
import { toggleBurgerPopup } from "../../state/popupStatus/popupStatusSlice";
import { setIsLoading } from "../../state/meta/metaSlice";

const Navigation = ({ type="menu" }) => {
    const meta = useSelector((state) => state.meta)
    const language = meta.language

    const isBurgerMenuOpen = useSelector((state) => state.popups.isBurgerPopupOpen)
    const dispatch = useDispatch()

    const pages = {
        ru: [
            {
                name: "Области практики",
                link: "/practice-areas",
            },
            {
                name: "Команда",
                link: "/team",
            },
            {
                name: "Новости",
                link: "/news",
            },
            {
                name: "Контакты",
                link: "/contact-us",
            },
        ],
        en: [
            {
                name: "Practice areas",
                link: "/practice-areas",
            },
            {
                name: "Team",
                link: "/team",
            },
            {
                name: "News",
                link: "/news",
            },
            {
                name: "Contacts",
                link: "/contact-us",
            },
        ],
        kz: [
            {
                name: "Тәжірибе аймақтары",
                link: "/practice-areas",
            },
            {
                name: "Команда",
                link: "/team",
            },
            {
                name: "Жаңалықтар",
                link: "/news",
            },
            {
                name: "Контактілер",
                link: "/contact-us",
            },
        ]
    };
    const lang = 'EN';

    function handleClick() {
        dispatch(setIsLoading(true))
        if (type === "burger") {
            dispatch(toggleBurgerPopup())
        }
    }

    return (
        <nav className={`navigation navigation_type_${type}`}>
            <ul className={`navigation__list navigation__list_type_${type}`}>
                {pages[language].map((page, index) => {
                    return (
                        <li key={index}>
                            <NavLink className={({ isActive }) => `${isActive ? "navigation__link navigation__link_active" : "navigation__link"}`} to={page.link} onClick={handleClick}>{ page.name }</NavLink>
                        </li>
                    )
                })}
                <LangSwitcher />
            </ul>
            {
                !isBurgerMenuOpen
                &&
                <button className={`navigation__btn-open-burger navigation__btn-open-burger_type_${type}`} onClick={() => { dispatch(toggleBurgerPopup()) }}></button>
            }
        </nav>
    )
}

export default Navigation;
