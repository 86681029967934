import { createSlice } from "@reduxjs/toolkit"

function getDefaultLocalStorage() {
    let storageLocale = localStorage.getItem("language")
    let defaultLocale = "en"

    if (storageLocale) return storageLocale
    return defaultLocale
}

// Дефолтные значения стейта
// language: 0 - Русский, 1 - Казахский, 3 - Английский
const initialState = {
    language: getDefaultLocalStorage(),
    location: 0,
    isLoading: true,
}

export const metaSlice = createSlice({
    name: "meta",
    initialState,
    reducers: {
        changeLanguage(state, action) {
            state.language = action.payload;
            localStorage.setItem("language", action.payload)
        },
        changeLocation(state, action) {
            state.location = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        }
    }
})

export const { changeLanguage, changeLocation, setIsLoading } = metaSlice.actions;
export default metaSlice.reducer;
