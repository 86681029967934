import "./TeamPage.css"
import { useSelector } from "react-redux/es/hooks/useSelector"

import Header from "../Header/Header"
import EmployeeList from "../Employee List/EmployeeList"
import Footer from "../Footer/Footer"

const TeamPage = () => {
    const language = useSelector((state) => state.meta.language)

    const pagesState = useSelector((state) => state.pages)

    const employees = pagesState.employee[language]

    return(
        <>
            <Header page="mainPage"color="white" style={{ marginBottom: "70px" }} border="true" />
            <EmployeeList employeeList={employees} type="gallery" />
            <Footer />
        </>
    )
}

export default TeamPage