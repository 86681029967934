import { useDispatch, useSelector } from "react-redux"
import "./ContactUs.css"
import { changeLocation } from "../../state/meta/metaSlice"

const ContactUs = ({ contacts, style }) => {
    const dispatch = useDispatch()
    const meta = useSelector((store) => store.meta)
    const language = meta.language
    const location = meta.location

    const sectionTitleVariation = {
        ru: "Контакты",
        en: "Contact Us",
        kz: "Контактілер",
    }

    // const cities = {
    //     ru: [
    //         "Нурсултан",
    //         "Алматы",
    //         "Атирау",
    //         "Актау",
    //     ],
    //     en: [
    //         "Nur-sultan",
    //         "Almaty",
    //         "Atirau",
    //         "Aktau",
    //     ],
    //     kz: [
    //         "Нұрсұлтан",
    //         "Алматы",
    //         "Атырау",
    //         "Ақтау",
    //     ],
    // }

    function setLocation(locationID) {
        dispatch(changeLocation(locationID))
    }

    return (
        <section className="contact-us" style={style}>
            <h2 className="contact-us__title">{sectionTitleVariation[language]}</h2>

            <div className="contact-us__details-container">
                <div className="contacts-us__map-container">
                    <div className="contact-us__map-wrapper">
                        <div className="contact-us__map" dangerouslySetInnerHTML={{__html: contacts[location]?.mapElement}}></div>
                        {
                            contacts[location]?.address
                            &&
                            <p className="contact-us__adress">{ contacts[location]?.address }</p>
                        }
                    </div>
                    <ul className="contact-us__cities-list">
                        {
                            contacts.map((city, index) => {
                                return (
                                    <li className={`contact-us__city ${index == location ? "contact-us__city_active" : ""}`} key={index} onClick={() => { setLocation(index) }}>{contacts[index].contactCity}</li>
                                )
                            })
                        }
                    </ul>
                </div>

                <div className="contacts-us__contacts-container">
                    <div className="contacts-us__email-credentials-container">
                        <a href={`mailto:${contacts[location]?.email}`} className="contact-us__email-credential">{contacts[location]?.email}</a>
                        <a href={`tel:${contacts[location]?.phone}`} className="contact-us__email-credential">{contacts[location]?.phone}</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactUs;