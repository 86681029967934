import { publicGalleryURL } from "../constants/webConfig";

export function normalizePracticeArea(practiceArea) {
    return {
        ...practiceArea,
        cardImage: `${publicGalleryURL}${practiceArea.cardImage?.data?.attributes?.url}`,
        promoImage: `${publicGalleryURL}${practiceArea.promoImage?.data?.attributes?.url}`,
        employees: practiceArea.employees?.data?.map((employee) => {
            return {
                ...employee.attributes
            }
        }),
        services: practiceArea.services?.data?.map((service) => {
            return {
                ...service.attributes
            }
        }),
    }
}