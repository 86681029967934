import { baseURL, testURL } from "../constants/webConfig";

class MainApi {
    constructor(baseUrl, headers) {
        this._baseUrl = baseUrl;
        this._headers = headers;
    }

    _getError(res) {
        if (!res.ok) {
            return Promise.reject(res.status);
        }
    }

    _getJSON(res) {
        if (res.ok) {
            return res.json();
        }

        return this._getError(res);
    }

    getMainPromo() {
        return fetch(`${this._baseUrl}/main-promo?locale=all&populate[0]=advBlock&populate[1]=advBlock.image&populate[2]=image&pagination[pageSize]=400`, {
            method: "GET",
            headers: {
                ...this._headers,
            },
        })
            .then((res) => this._getJSON(res))
    }

    getEmployees() {
        return fetch(`${this._baseUrl}/employees-gallery?locale=all&populate[0]=employee&populate[1]=employee.avatar&populate[2]=employee.practiceAreas&populate[3]=employee.articles&pagination[pageSize]=400`, {
            method: "GET",
            headers: {
                ...this._headers,
            },
        })
            .then((res) => this._getJSON(res))
            .then((res) => {
                return res
            })
    }

    getNews() {
        return fetch(`${this._baseUrl}/news?locale=all&populate=*&pagination[pageSize]=400`, {
            method: "GET",
            headers: {
                ...this._headers,
            },
        })
            .then((res) => this._getJSON(res))
    }

    // populate[0]=services&populate[1]=services.servicesInfo&populate[2]=cardImage&populate[3]=description&populate[4]=employees&populate[5]=news&populate[6]=promoImage&populate[7]=shortDescription&populate[8]=title
    getPracticeAreas() {
        return fetch(`${this._baseUrl}/practice-areas?locale=all&populate[0]=new_services&populate[1]=cardImage&populate[2]=description&populate[3]=employees&populate[4]=news&populate[5]=promoImage&populate[6]=shortDescription&populate[7]=title&pagination[pageSize]=400`, {
            method: "GET",
            headers: {
                ...this._headers,
                // Authorization: "BEARER 055818dea276fa3ada0400aaf22fed11a4286a692bd4f4ec320d6b7c7244dc09286b90c543834d780fe9b7f1c448a1a7ee40d7eccb5075db760f1a3eda22a643f477e27d153aa2aa8f5c679afadbd71ecc13a8ca7e75a834a57591cad7db012479de586e51f05d38a609a5b1f248b10ba756b22dd3246799c327d7cfb42e586c"
            },
        })
            .then((res) => this._getJSON(res))
    }

    getContactsPage() {
        return fetch(`${this._baseUrl}/contact?locale=all&populate[0]=advBlock&populate[1]=advBlock.image&populate[2]=City&pagination[pageSize]=400`, {
            method: "GET",
            headers: {
                ...this._headers,
                // Authorization: "BEARER 055818dea276fa3ada0400aaf22fed11a4286a692bd4f4ec320d6b7c7244dc09286b90c543834d780fe9b7f1c448a1a7ee40d7eccb5075db760f1a3eda22a643f477e27d153aa2aa8f5c679afadbd71ecc13a8ca7e75a834a57591cad7db012479de586e51f05d38a609a5b1f248b10ba756b22dd3246799c327d7cfb42e586c"
            },
        })
            .then((res) => this._getJSON(res))
    }

    getTeamPage() {
        return fetch(`${this._baseUrl}/team-page?locale=all&populate[0]=advBlock&populate[1]=advBlock.image&pagination[pageSize]=400`, {
            method: "GET",
            headers: {
                ...this._headers,
                // Authorization: "BEARER 055818dea276fa3ada0400aaf22fed11a4286a692bd4f4ec320d6b7c7244dc09286b90c543834d780fe9b7f1c448a1a7ee40d7eccb5075db760f1a3eda22a643f477e27d153aa2aa8f5c679afadbd71ecc13a8ca7e75a834a57591cad7db012479de586e51f05d38a609a5b1f248b10ba756b22dd3246799c327d7cfb42e586c"
            },
        })
            .then((res) => this._getJSON(res))
    }

    getPracticeAreasPage() {
        return fetch(`${this._baseUrl}/practice-areas-page?locale=all&populate[0]=advBlock&populate[1]=advBlock.image&pagination[pageSize]=400`, {
            method: "GET",
            headers: {
                ...this._headers,
                // Authorization: "BEARER 055818dea276fa3ada0400aaf22fed11a4286a692bd4f4ec320d6b7c7244dc09286b90c543834d780fe9b7f1c448a1a7ee40d7eccb5075db760f1a3eda22a643f477e27d153aa2aa8f5c679afadbd71ecc13a8ca7e75a834a57591cad7db012479de586e51f05d38a609a5b1f248b10ba756b22dd3246799c327d7cfb42e586c"
            },
        })
            .then((res) => this._getJSON(res))
    }


    sendApplication(reqBody) {
        let fileID;

        return fetch(`${this._baseUrl}/upload`, {
            method: "POST",
            headers: {
                ...this._headers,
            },
            body: reqBody.form,
        })
            .then((res) => this._getJSON(res))
            .then((res) => {
                fileID = res[0]
            })
            .then(() => {
                const body = {
                    data: {
                        resume: fileID,
                        email: reqBody.email,
                    }
                }

                return fetch(`${this._baseUrl}/zayavleniya-na-trudoustrojstvos`, {
                    method: "POST",
                    headers: {
                        ...this._headers,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(body),
                })
                    .then((res) => res.json())
            })
    }
}

// @todo Спрятать токен
const mainApi = new MainApi(baseURL, {
    // Authorization: "bearer d274c93bfdb4329d516c5be2e23dc7b95916fa18972fa082ab2bd2a96ab2bf98141a6a9e2ba00660a8ff3b88911fcffde736753f056e712f242417671faa7eb73e63d944b40b0fc821f2d0d05df9753c5c45116aefc6f7a69e0c2e9b9fc5132a232dc749fff0a593faa284a62a5bc0b6703961417dbdf86f9e340fbcbacd6654",
    Authorization: "bearer bc23531fb396ff091e35fde1ac8a164b861213f8eaa14cbcbaaa08edc37fd0ca7ce4d991efb445883ac16a447d3c7efa801a348d12335b498e9093de0f2d476ea360e1cf118f0eb94f03fe79433da0094c8bc0087f50c1f5fb1e93ed3fc8e8e5cb653b768b3e765a060f767f393c550526097389d5aabb989c73f268fa1aa3b0"
});

export default mainApi;
