import "./NewsList.css";
import React from 'react';
import Section from '../Section/Section';
import NewsCard from '../NewsCard/NewsCard';
import Pagination from '../UI/Pagination';
import {Swiper, SwiperSlide} from 'swiper/react';
import NewsPoster from "../NewsPoster/NewsPoster";
import {useSelector} from "react-redux";
import {logRoles} from "@testing-library/react";

const NewsList = ({news = [], type}) => {
    const [isMobile, setIsMobile] = React.useState("false")

    const language = useSelector((state) => state.meta.language)
    const sectionTitleVariation = {
        gallery: {
            ru: "",
            en: "",
            kz: "",
        },
        widget: {
            ru: "Новости",
            en: "News",
            kz: "Жаңалықтар",
        },
        poster: {
            ru: "",
            en: "",
            kz: "",
        }
    }

    const newsState = useSelector((state) => state.pages.news)[language]

    const widgetLayout = (
        <>
            <Swiper
                width={800}
                slidesPerView={1}
                spaceBetween={50}
                breakpoints={{
                    1000: {
                        width: 400
                    },
                    900: {
                        width: 420,
                        spaceBetween: 20
                    },
                    800: {
                        width: 400,
                    },
                    700: {
                        width: 360,
                        spaceBetween: 30,
                    },
                    600: {
                        width: 320,
                    },
                    500: {
                        width: 300,
                        spaceBetween: 20,
                    },
                    400: {
                        width: 300,
                        spaceBetween: 15,
                        slidesPerView: 1
                    },
                    320: {
                        width: 300,
                        slidesPerVies: 1,
                        spaceBetween: 10,
                    },
                }}
            >
                {
                    news.map((newsCard, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <NewsCard type={type} date={newsCard?.date || ""} title={newsCard?.title || ""}
                                          description={newsCard?.description || ""} id={newsState.indexOf(newsCard)}/>
                            </SwiperSlide>
                        )
                    })
                }
                <Pagination currentPage="1" pagesQuantity="5" style={{marginTop: "20px"}}/>
            </Swiper>
        </>
    )

    const galleryLayout = {
        false: (
            <>
                <div className={`news-list_type_${type}`} style={{marginBottom: '30px'}}>
                    {
                        news.slice(0, 1).map((newsCard, index) => {
                            return (
                                <NewsCard type={type} date={newsCard?.date || ""} title={newsCard?.title || ""}
                                          description={newsCard?.description || ""}
                                          author={newsCard?.author?.name || ""}
                                          readingTime={newsCard?.readingTime || ""}
                                          backgroundImage={newsCard?.backgroundImage || ""} key={index}
                                          id={newsState.indexOf(newsCard)}/>
                            )
                        })
                    }

                    <div className="news-list__flex-container">
                        {
                            news.slice(1, 3).map((newsCard, index) => {
                                return (
                                    <NewsCard className="news-card_type_gallery-little" type={type}
                                              date={newsCard?.date || ""} title={newsCard?.title || ""}
                                              description={newsCard?.description || ""}
                                              author={newsCard?.author?.name || ""}
                                              readingTime={newsCard?.readingTime || ""}
                                              backgroundImage={newsCard?.backgroundImage || ""} key={index} size="mini"
                                              id={newsState.indexOf(newsCard)}/>
                                )
                            })
                        }
                    </div>
                </div>

                {
                    news.length == 6
                    &&
                    <div className={`news-list_type_${type}`}>
                        {
                            news.slice(0, 1).map((newsCard, index) => {
                                return (
                                    <NewsCard type={type} date={newsCard?.date || ""} title={newsCard?.title || ""}
                                              description={newsCard?.description || ""}
                                              author={newsCard?.author?.name || ""}
                                              readingTime={newsCard?.readingTime || ""}
                                              backgroundImage={newsCard?.backgroundImage || ""} key={index}
                                              id={newsState.indexOf(newsCard)}/>
                                )
                            })
                        }

                        <div className="news-list__flex-container">
                            {
                                news.slice(1, 3).map((newsCard, index) => {
                                    return (
                                        <NewsCard className="news-card_type_gallery-little" type={type}
                                                  date={newsCard?.date || ""} title={newsCard?.title || ""}
                                                  description={newsCard?.description || ""}
                                                  author={newsCard?.author?.name || ""}
                                                  readingTime={newsCard?.readingTime || ""}
                                                  backgroundImage={newsCard?.backgroundImage || ""} key={index}
                                                  size="mini" id={newsState.indexOf(newsCard)}/>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </>
        ),
        true: (
            <div className={`news-list_type_${type}`} style={{marginBottom: '30px'}}>
                {
                    news.map((newsCard, index) => {
                        return <NewsCard className="news-card_type_gallery" type={type} date={newsCard?.date || ""}
                                         title={newsCard?.title || ""} description={newsCard?.description || ""}
                                         author={newsCard?.author?.name || ""} readingTime={newsCard?.readingTime || ""}
                                         backgroundImage={newsCard?.backgroundImage || ""} key={index}
                                         id={newsState.indexOf(newsCard)}/>
                    })
                }
            </div>
        )
    }

    const posterLayout = (
        <div className={`news-list_type_${type}`}>
            {
                news.slice(0, 1).map((newsCard, index) => {
                    return (
                        <NewsCard type={"gallery"} className="gallery-poster" date={newsCard?.date || ""}
                                  title={newsCard?.title || ""} description={newsCard?.description || ""}
                                  author={newsCard?.author?.name || ""}
                                  readingTime={newsCard?.readingTime || ""}
                                  backgroundImage={newsCard?.backgroundImage || ""} key={index}
                                  id={newsState.indexOf(newsCard)}/>
                    )
                })
            }

            <div className="news-list__container_type_poster">
                {
                    news.slice(1, 3).map((newsCard, index) => {
                        return (
                            <NewsPoster type={type} date={newsCard?.date || ""}
                                        title={newsCard?.title || ""}
                                        description={newsCard?.description || ""}
                                        author={newsCard?.author?.name || ""}
                                        readingTime={newsCard?.readingTime || ""}
                                        backgroundImage={newsCard?.backgroundImage || ""} key={index}
                                        id={newsState.indexOf(newsCard)}/>
                        )
                    })
                }
            </div>
        </div>
    )

    function getServicesListState() {
        const checkIsMobile = window.matchMedia("(min-width: 320px) and (max-width: 500px)").matches;

        if (checkIsMobile) {
            setIsMobile("true")
        } else {
            setIsMobile("false")
        }
    }

    function handleResize() {
        setTimeout(() => {
            getServicesListState()
        }, 1)
        window.removeEventListener("resize", handleResize);
        window.addEventListener("resize", handleResize);
    }

    React.useEffect(() => {
        getServicesListState()

        window.addEventListener("resize", handleResize)
    })

    const layoutTypes = {
        widget: widgetLayout,
        gallery: galleryLayout,
        poster: posterLayout,
    }

    return (
        <>
            {
                news.length >= 1
                &&
                <Section name={`${type}-news-list`} title={sectionTitleVariation[type][language].toUpperCase() || ""}
                         style={{position: "relative", overflow: "hidden"}}>
                    {layoutTypes[type][isMobile] || layoutTypes[type]}
                </Section>
            }
        </>
    )
}

export default NewsList;