import './Block.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../UI/Button';

const Block = ({ type = "default", style, backgroundImage, innerImage, innerImageAlt, text, children, button, buttonText }) => {
    const navigate = useNavigate();

    const defaultBlockLayout = (
        <>
            {children}
        </>
    )

    // Временная функция. Переписать!!!
    function handleContactUsButtonClick() {
        navigate('/contact-us');
    }

    const advertisementLayout = (
        <>
            <img className="block__background" src={innerImage} alt={innerImageAlt && 'ALC Legal advertisement photo'} />
            <div className="block__container block__container_type_advertisement">
                {
                    text.map((text, index) => (
                        <p className="block__paragraph_type_advertisement" key={index}>{text}</p>
                    ))
                }

                {
                    button
                    &&
                    <Button type="primary" onClick={handleContactUsButtonClick}>{buttonText}</Button>
                }
            </div>
        </>
    )

    const blockTypesLayout = {
        default: defaultBlockLayout,
        advertisement: advertisementLayout,
    }

    const blockTypesStyles = {
        default: "block_type_default",
        advertisement: "block_type_advertisement",
        form: "block_type_form",
    }

    return (
        <div className={`block ${blockTypesStyles[type]}`} style={{ ...style }}>
            {
                blockTypesLayout[type]
                ||
                blockTypesLayout.default
            }
        </div>
    );
};

export default Block;
