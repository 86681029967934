import './UI.css';
import React from 'react';

const Button = ({ type, children, onClick }) => {
    const buttonTypes = {
        primary: 'button_type_primary',
        secondary: 'button_type_secondary',
        oval: 'button_type_oval',
        accordion: 'button_type_accordion',
    };

    function handleClick(evt) {
        evt.preventDefault();
        onClick();
    }

    return (
        <button className={`button ${buttonTypes[type]}`} onClick={handleClick}>{children}</button>
    );
};

export default Button;
