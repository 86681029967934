import "./ServicesList.css"

import { useSelector } from "react-redux/es/hooks/useSelector"

import ServiceAccordion from "../ServiceAccordion/ServiceAccordion"
import { NavLink } from "react-router-dom"
import React from "react"

const ServicesList = ({ id, style }) => {
    const [isMobile, setIsMobile] = React.useState("false")

    const language = useSelector((state) => state.meta.language)

    const pagesState = useSelector((state) => state.pages)

    const practiceAreasList = pagesState.practiceAreas[language]
    const practiceArea = practiceAreasList[id]
    const services = practiceArea.new_services

    const serviceTitle = {
        ru: "Мы оказываем следующие услуги",
        en: "We provide the following services",
        kz: "Біз келесі қызметтерді көрсетеміз",
    }

    const practiceAreas = {
        ru: "Области практики",
        en: "Practice Areas",
        kz: "Тәжірибе аймақтары"
    }

    const isMobileVariations = {
        true: (
            <ul className="services-list__container" style={style}>
                <div className="services-list__column">
                    {
                        services.map((service, index) => {
                            return <ServiceAccordion content={service} key={index} index={index} />
                        })
                    }
                </div>
            </ul>
        ),
        false: (
            <ul className="services-list__container" style={style}>
                <div className="services-list__column">
                    {
                        services.map((service, index) => {
                            if (index % 2 === 0) return <ServiceAccordion content={service} key={index} index={index} />
                        })
                    }
                </div>

                <div className="services-list__column">
                    {
                        services.map((service, index) => {
                            if (index % 2 !== 0) return <ServiceAccordion content={service} key={index} index={index} />
                        })
                    }
                </div>
            </ul>
        )
    }

    function getServicesListState() {
        const checkIsMobile = window.matchMedia("(min-width: 320px) and (max-width: 500px)").matches;

        if (checkIsMobile) {
            setIsMobile("true")
        } else {
            setIsMobile("false")
        }
    }

    function handleResize() {
        setTimeout(() => { getServicesListState() }, 1)
        window.removeEventListener("resize", handleResize);
        window.addEventListener("resize", handleResize);
    }

    React.useEffect(() => {
        getServicesListState()

        window.addEventListener("resize", handleResize)
    })

    const breadCrumbs = `${practiceAreas[language]} / ${practiceAreasList[id].title}`

    return (
        <section className="services-list">
            <div className="bread-crumbs">
                <NavLink to="/practice-areas" style={{ textDecoration: "none", color: "inherit" }}>{practiceAreas[language]} </NavLink>
                &nbsp;/ {practiceAreasList[id].title}
            </div>
            <h3 className="services-list__title">{serviceTitle[language]}</h3>
            {
                isMobileVariations[isMobile]
            }
        </section>
    )
}

export default ServicesList
