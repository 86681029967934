import { configureStore } from "@reduxjs/toolkit";
import metaSlice from "./meta/metaSlice";
import pagesSlice from "./pages/pagesSlice";
import popupStatusSlice from "./popupStatus/popupStatusSlice";
import createSagaMiddleware from "redux-saga"
import { stateWatcher } from "./saga/saga";

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: {
        meta: metaSlice,
        pages: pagesSlice,
        popups: popupStatusSlice,
    },
    middleware: [sagaMiddleware],
})

sagaMiddleware.run(stateWatcher)
