import { useSelector } from "react-redux/es/hooks/useSelector"

import Header from "../Header/Header"
import ContactUs from "../ContactUs/ContactUs"
import Block from "../Block/Block"

import Footer from "../Footer/Footer"
import JoinUsForm from "../JoinUsForm/JoinUsForm";
import React from "react";

const ContactsPage = () => {
    const language = useSelector((state) => state.meta.language)

    const pagesState = useSelector((state) => state.pages)
    const contacts = pagesState.contacts[language][0].City
    const advBlock = pagesState?.contacts[language][0]?.advBlock

    const advertisement = {
        "ru": {
            "image": "",
            "text": ["Our mission is to grow continuously with clients and share all the time the best principles and practices which we have been doing for the past decade"]
        },
        "en": {
            "image": "",
            "text": ["Our mission is to grow continuously with clients and share all the time the best principles and practices which we have been doing for the past decade"]
        },
        "kz": {
            "image": "",
            "text": ["Our mission is to grow continuously with clients and share all the time the best principles and practices which we have been doing for the past decade"]
        }
    }
    const advertisementText = advertisement[language].text

    return (
        <>
            <Header page="mainPage" color="white" border="true" style={{ marginBottom: "50px" }} />
            <ContactUs contacts={contacts} style={{ marginBottom: "50px" }} />
            <Block type="form" style={{ marginBottom: "50px" }} text={[]}>
                <JoinUsForm />
            </Block>
            <Block type="advertisement" style={{ marginBottom: "100px" }} innerImage={advBlock?.image} text={[advBlock?.text]} button={advBlock.button} buttonText={advBlock.buttonText} />
            <Footer />
        </>
    )
}

export default ContactsPage