import "./UI.css";
import React from 'react';
import useInput from '../../utils/hooks/useInput';

function Input({ type = "default", isReqired, value, defaultValue = "", placeholder, className = "", onClick, style, disabled, setEmailInput }) {
    const emailValidationConfig = {
        minLength: 4,
        maxLength: 20,
    }

    const validationVariations = {
        default: {},
        email: emailValidationConfig,
        file: {},
    }

    const [inputValue, handleInput, handleFocus, hasError, error] = useInput(defaultValue, {
        type: type,
        required: isReqired,
        ...validationVariations[type],
    })

    React.useEffect(() => {
        setEmailInput(inputValue)
    }, [inputValue])

    const classVariations = {
        default: "",
        email: "input_type_email",
        file: "input_type_file",
    }

    return (
        <>
            <input className={`input ${classVariations[type]} ${className}`} style={style} type={type} placeholder={placeholder} value={value || inputValue} disabled={type === "file"} onInput={handleInput} onFocus={handleFocus} onClick={onClick} />
        </>
    );
}

export default Input;
