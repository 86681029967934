import './Header.css';
import Navigation from "../Navigation/Navigation";
import Logo from '../Logo/Logo';

const Header = ({ page, color, style, border = "false" }) => {
  const headerPageVariations = {
    mainPage: "header__container_page_main",
    teamPage: "header__container_page_team"
  }

  const headerColorVariations = {
    white: "header_color_white",
    gray: "header_color_gray",
  }

  const borderVartiations = {
    true: "header_border",
    false: ""
  }

  return (
    <header className={`header ${headerColorVariations[color]} ${borderVartiations[border]}`} style={style}>
      <div className={`header__container ${headerPageVariations[page]}`}>
        <Logo />
        <Navigation />
      </div>
    </header>
  );
}

export default Header;
