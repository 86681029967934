import './Form.css';
import React from "react";

const Form = ({ title, subtitle = "", icon = "", type = "default", children, onSubmit }) => {
    const stylesVariations = {
        default: '',
        joinUs: 'form_type_join-us',
    }

    const joinUsTitles = (
        <div className="form__title-container form__title-container_type_join-us">
            <h2 className="form__title form__title-join-us">{title}</h2>
            <div className="form__title-icon" style={{ backgroundImage: `url(${icon})` }}></div>
        </div>
    )

    const titleVariations = {
        default: "",
        joinUs: joinUsTitles
    }

    function handleSubmit(evt) {
        evt.preventDefault()
        onSubmit(evt)
    }

    return(
        <form className={`form ${stylesVariations[type]}`} onSubmit={handleSubmit}>
            {titleVariations[type]}
            {children}
        </form>
    )
}

export default Form;
