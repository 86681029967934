import { createSlice } from "@reduxjs/toolkit"

// Дефолтное значение стейта
const initialState = {
    isBurgerPopupOpen: false,
}

export const popupStatusSlice = createSlice({
    name: "popupSlice",
    initialState,
    reducers: {
        toggleBurgerPopup(state, action) {
            state.isBurgerPopupOpen = !state.isBurgerPopupOpen
        }
    }
})

export const { toggleBurgerPopup } = popupStatusSlice.actions;
export default popupStatusSlice.reducer;
