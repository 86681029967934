import "./NewsPoster.css"
import {useLocation, useNavigate} from 'react-router-dom';
import { useSelector } from 'react-redux';

const NewsPoster = ({ title, backgroundImage, author, readingTime, id }) => {
    const navigate = useNavigate()
    const language = useSelector((state) => state.meta.language)
    const employeeState = useSelector((store) => store.pages.employee)
    const employee = employeeState[language]

    const location = useLocation()

    let employeeID = 0
    employee.forEach((item, i) => {
        if (item.name === author) employeeID = i
    })

    function getMinutes(minutes) {
        if (minutes === 1) {
            return `${minutes} минута`;
        } else if (/^[2-4]$/.test(minutes)) {
            return `${minutes} минуты`;
        } else if (/^[5-9]$/.test(minutes)) {
            return `${minutes} минут`;
        } else if (/^[1][0-9]$/.test(minutes)) {
            return `${minutes} минут`;
        } else if (/^\d+[1]$/.test(minutes)) {
            return `${minutes} минута`
        } else if (/^\d+[2-4]$/.test(minutes)) {
            return `${minutes} минуты`;
        } else if (/^\d+[5-90]$/.test(minutes)) {
            return `${minutes} минут`;
        }
    }

    const readingTimeVariations = {
        ru: getMinutes(readingTime),
        en: `${readingTime} min read`,
        kz: getMinutes(readingTime),
    }

    function handleReadMore() {
        navigate(`/news/${id}`, { state: { prevPath: location.pathname }})
    }

    function handleClickToAuthor(e) {
        e.stopPropagation()
        navigate(`/team/${employeeID}`, { state: { prevPath: location.pathname }})
    }

    return (
        <div className="news-poster" onClick={handleReadMore}>
            <img className="news-poster__image" src={backgroundImage} alt="Изображение" />
            <div className="news-poster__container">
                <h4 className="news-poster__title">{ title }</h4>

                <div className="news-poster__author-container" onClick={handleClickToAuthor}>
                    <p className="news-poster__author"><span className="author-span-black">{ author }</span></p>
                    <p className="news-poster__reading-time">{ readingTimeVariations[language] }</p>
                </div>
            </div>
        </div>
    )
}

export default NewsPoster
