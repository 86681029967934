import { useSelector } from "react-redux"

import Header from "../Header/Header"
import PracticeAreasList from "../PraticeAreasList/PracticeAreasList"
import NewsList from "../NewsList/NewsList"
import Block from "../Block/Block"
import Footer from "../Footer/Footer"
import { publicGalleryURL } from "../../utils/constants/webConfig"

const PracticeAreasPage = () => {
    const language = useSelector((state) => state.meta.language)

    const pagesState = useSelector((state) => state.pages)

    const practiceAreasList = pagesState.practiceAreas[language]
    const newsList = pagesState.news[language]

    const practiceAreasPage = pagesState.practiceAreasPage
    const advBlock = practiceAreasPage[language][0].advBlock

    return(
        <>
            <Header page="mainPage" color="white" style={{ marginBottom: "70px" }} border="true" />
            <PracticeAreasList name="practice-areas-main" type="main" practiceAreas={practiceAreasList} style={{ marginBottom: '110px' }} />
            <NewsList news={newsList} type="widget" />
            <Block type="advertisement" style={{ marginBottom: "100px" }} innerImage={`${publicGalleryURL}${advBlock.image?.attributes?.url}`} text={[advBlock.text]} button={advBlock.button} buttonText={advBlock.buttonText} />
            <Footer />
        </>
    )
}

export default PracticeAreasPage