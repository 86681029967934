import "./footer.css"
import React from "react"
import { useSelector } from "react-redux/es/hooks/useSelector"

import Logo from '../Logo/Logo';
import Navigation from "../Navigation/Navigation";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__divider">
                <Logo />
                <h2 className="footer__thesis">IN ANY CASE</h2>
            </div>
            <div className="divider"></div>
            <Navigation type="footer" />

            <div className="contacts">
                <a href={`mailto:office@alc.legal`} className="contacts__credential">office@alc.legal</a>
                <a href={`tel:+77075056006`} className="contacts__credential">+7 707 505-6006</a>
            </div>
        </footer>
    )
}

export default Footer
