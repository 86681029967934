import "./PracticeAreasList.css";
import React from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";
import Section from "../Section/Section";
import Button from "../UI/Button";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../state/meta/metaSlice";

function PracticeAreasList({ name, type, style, practiceAreas }) {
    const language = useSelector((state) => state.meta.language)
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const sectionTitleVariations = {
        ru: "Области практики",
        en: "Practice Areas",
        kz: "Тәжірибе салалары",
    }

    const buttonLocalization = {
        ru: "Подробнее",
        en: "More",
        kz: "Толығырақ",
    }

    const mainLayout = (
        <ul className="practice-areas-list practice-areas-list_type_main">
            {
                practiceAreas.map((practiceArea, index) => {
                    return (
                        <li className="practice-areas-list__item_type_main" key={index}>
                            <div className="practice-areas-list__item-text-container_type_main">
                                <h4 className="practice-areas-list__item-title_type_main">{practiceArea.title}</h4>
                                <p className="practice-areas-list__item-description_type_main">{practiceArea.shortDescription}</p>
                            </div>

                            <Button type="primary" onClick={() => {
                                dispatch(setIsLoading(true)); navigate(`/practice-areas/${index}`)
                            }}>
                                {buttonLocalization[language]}
                            </Button>

                            <div className="practice-areas-list__item-background" style={{ backgroundImage: `url(${practiceArea.cardImage})` }}>
                                <div className="practice-areas-list__item-background-overlay"></div>
                            </div>
                        </li>
                    )
                })
            }
        </ul>
    )

    const additionalLayout = (
        <ul className="practice-areas-list">
            {
                practiceAreas.map((practiceArea, index) => {
                    return (
                        <li className="practice-areas-list__item_type_additional" key={index}>
                            <div className="practice-areas-list__item-text-container_type_additional">
                                <p className="practice-areas-list__item-numeration_type_additional">0{index + 1}</p>
                                <h3 className="practice-areas-list__item-title_type_additional">{practiceArea.title}</h3>
                            </div>
                            <Button type="oval" onClick={() => {
                                dispatch(setIsLoading(true));
                                navigate(`/practice-areas/${index}`)
                            }}>
                                {buttonLocalization[language]}
                            </Button>
                        </li>
                    )
                })
            }
        </ul>
    )

    const practiceAreasTypes = {
        main: mainLayout,
        additional: additionalLayout,
    }

    return (
        <Section name={name} title={sectionTitleVariations[language]} style={style} color="white">
            {practiceAreasTypes[type]}
        </Section>
    );
}

export default PracticeAreasList;
