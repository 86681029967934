import './NewsCard.css';
import React from 'react';
import Button from '../UI/Button';
import {useLocation, useNavigate} from 'react-router-dom';
import { useSelector } from 'react-redux';

const NewsCard = ({ type = "widget", className, date, title, description, author, readingTime, backgroundImage, size = "default", id }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const language = useSelector((state) => state.meta.language)

    const employeeState = useSelector((store) => store.pages.employee)
    const employee = employeeState[language]

    let employeeID = 0
    employee.forEach((item, i) => {
        if (item.name === author) employeeID = i
    })

    function handleReadMore() {
        navigate(`/news/${id}`, {state: {prevPath: location.pathname }})
    }

    function handleClickToAuthor(e) {
        e.stopPropagation()
        navigate(`/team/${employeeID}`, { state: { prevPath: location.pathname }})
    }

    function getMinutes(minutes) {
        if (minutes === 1) {
            return `${minutes} минута`;
        } else if (/^[2-4]$/.test(minutes)) {
            return `${minutes} минуты`;
        } else if (/^[5-9]$/.test(minutes)) {
            return `${minutes} минут`;
        } else if (/^[1][0-9]$/.test(minutes)) {
            return `${minutes} минут`;
        } else if (/^\d+[1]$/.test(minutes)) {
            return `${minutes} минута`
        } else if (/^\d+[2-4]$/.test(minutes)) {
            return `${minutes} минуты`;
        } else if (/^\d+[5-90]$/.test(minutes)) {
            return `${minutes} минут`;
        }
    }

    const readingTimeVariations = {
        ru: getMinutes(readingTime),
        en: `${readingTime} min read`,
        kz: getMinutes(readingTime),
    }

    const readVariations = {
        ru: "Читать",
        en: "Read",
        kz: "Оқу"
    }

    const widgetLayout = (
        <div className={`news-card news-card_type_${type}`}>
            <div className={`news-card__text-container news-card__text-container_type_${type}`}>
                <p className={`news-card__date news-card__date_type_${type}`}>{date}</p>
                <h3 className={`news-card__title news-card__title_type_${type}`}>{title}</h3>
                <p className={`news-card__description news-card__description_type_${type}`}>{description}</p>
            </div>

            <Button type="primary" onClick={handleReadMore}>{ readVariations[language] }</Button>
        </div>
    )

    const galleryLayout = (
        <div className={`news-card_type_${type} ${size === "mini" ? "news-card_type_gallery-little" : ""} ${className}`} style={{ backgroundImage: `url(${backgroundImage})` }} onClick={handleReadMore}>
            <div className="news-card__overlay"></div>
            <div className={`news-card__text-container news-card__text-container_type_${type}`}>
                <p className={`news-card__title news-card__title_type_${type} ${size === "mini" ? "news-card__title-mini_type_gallery" : ""}`}>{title}</p>

                <div className={`news-card__description-container news-card__description-container_type_${type}`}>
                    <div className={`news-card__info-container`}>
                        <p className={`news-card__author news-card__author_type_${type} ${size === "mini" ? "news-card__author-mini_type_gallery" : ""}`} onClick={handleClickToAuthor}><span className="author-span">{author}</span></p>
                        {
                            size !== "mini"
                            &&
                            <p className={`news-card__reading-time news-card__reading-time_type_${type}`}> { readingTimeVariations[language] } </p>
                        }
                    </div>

                    <button className={`news-card__btn-read ${size === "mini" ? "news-card__btn-read-mini" : ""}`} onClick={handleReadMore}></button>
                </div>
            </div>
        </div>
    )

    const layoutTypes = {
        widget: widgetLayout,
        gallery: galleryLayout,
    }

    return (
        <>
            {layoutTypes[type]}
        </>
    );
}

export default NewsCard;
