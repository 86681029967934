import "./LangSwitcher.css";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { changeLanguage } from "../../state/meta/metaSlice";
import { motion, AnimatePresence } from "framer-motion";

const LangSwitcher = () => {
    const dispatch = useDispatch()
    const [dropdownShown, setDropdownShown] = React.useState(false)

    const meta = useSelector((store) => store.meta)
    const language = meta.language

    const languages = ["RU", "EN", "KZ"]

    const currentLang = language.toUpperCase();

    function changeLocalization(lang) {
        const newLanguage = lang.toLowerCase()
        dispatch(changeLanguage(newLanguage))
        setDropdownShown(false)
    }

    return (
        <>
            <button className="current-lang" onClick={() => { setDropdownShown(!dropdownShown) }}>
                {currentLang}

                <AnimatePresence initial={false}>
                    {
                        dropdownShown
                        &&
                        (
                            <motion.ul
                                className="dropdown"
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                                onMouseLeave={() => { setDropdownShown(false) }}
                            >
                                {
                                    languages.map((lang, index) => {
                                        if (lang === currentLang) {
                                            return (
                                                <motion.li className={`dropdown__item ${lang === currentLang ? "dropdown__item_active" : ""}`} key={index} onClick={() => { changeLocalization(lang) }}>{lang}</motion.li>
                                            )
                                        }
                                    })
                                }

                                {
                                    languages.map((lang, index) => {
                                        if (lang !== currentLang) {
                                            return (
                                                <motion.li className={`dropdown__item ${lang === currentLang ? "dropdown__item_active" : ""}`} key={index} onClick={() => { changeLocalization(lang) }}>{lang}</motion.li>
                                            )
                                        }
                                    })
                                }
                            </motion.ul>
                        )
                    }
                </AnimatePresence>
            </button>
        </>
    )
}

export default LangSwitcher;
