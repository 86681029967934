import "./EmployeeCard.css";
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {setIsLoading} from "../../state/meta/metaSlice";
import parseRichText from "../../utils/functions/parseRichText";

const EmployeeCard = ({type, employee, id}) => {
    const navigate = useNavigate()
    const location = useLocation()

    const disptach = useDispatch()
    const meta = useSelector((store) => store.meta)
    const language = meta.language

    const typeStyles = {
        widget: "employee-card__widget",
        gallery: "employee-card__gallery",
    }

    const cardLocalization = {
        ru: {
            keyCompetencies: "Ключевые компетенции",
            education: "Образование",
        },
        en: {
            keyCompetencies: "Key competencies",
            education: "Education",
        },
        kz: {
            keyCompetencies: "Негізгі құзыреттер",
            education: "Білім",
        },
    }

    const galleryLayout = (
        <div className={`employee-card employee-card_type_${type}`} onClick={() => {
            disptach(setIsLoading(true))
            navigate(`/team/${id}`, {state: {prevPath: "/team"} })
        }}>
            <div className={`employee-card__avatar-container employee-card__avatar-container_type_${type}`}>
                <p className={`employee-card__position employee-card__position_type_${type} employee-card__position-accent_type_${type}`}>{employee.position}</p>
                <img className={`employee-card__avatar employee-card__avatar_type_${type}`} src={employee?.avatar || ""}
                     alt={`Portrait of employee ${employee?.name || ""}`}/>
            </div>

            <div className={`employee-card__container employee-card__container_type_${type}`}>

                <h3 className={`employee-card__name employee-card__name_type_${type}`}
                    onClick={(evt) => evt.stopPropagation()}>{employee?.name || ""}</h3>

                <div className="employee-card__employee-info-container">
                    <div>
                        <p className="employee-card__subheading accentiate-text"
                           onClick={(evt) => evt.stopPropagation()}>{cardLocalization[language].keyCompetencies}</p>
                        <ul className="employee-card__key-competencies-list" onClick={(evt) => evt.stopPropagation()}>
                            {
                                employee.newKeyCompetencies
                                &&
                                parseRichText(employee.newKeyCompetencies, "employee-block__competencies-list-item", "disc", { color: "black" })
                            }
                        </ul>
                    </div>

                    <div>
                        <p className="employee-card__subheading employee-card__subheading_education accentiate-text"
                           onClick={(evt) => evt.stopPropagation()}>{cardLocalization[language].education}</p>
                        <p className="employee-card__education"
                           onClick={(evt) => evt.stopPropagation()}>{employee.education}</p>
                    </div>
                </div>
            </div>
        </div>
    )

    const employeeCardWidgetLayoyt = (
        <div className="employee-card" onClick={() => {
            disptach(setIsLoading(true))
            navigate(`/team/${id}`, {state: {prevPath: location.pathname}})
        }}>
            <div className="employee-card__avatar-container">
                <img className={`employee-card__avatar employee-card__avatar_type_${type}`} src={employee?.avatar || ""}
                     alt={`Portraiot of employee ${employee?.name || ""}`}/>
            </div>

            <div className="employee-card__container">
                <div>
                    <h3 className="employee-card__name"
                        onClick={(evt) => evt.stopPropagation()}>{employee?.name || ""}</h3>
                    <p className={`employee-card__position employee-card__position_type_${type}`}
                       onClick={(evt) => evt.stopPropagation()}>{employee?.position || ""}</p>
                </div>

                <div className="employee-card__employee-info-container">
                    <div>
                        <p className="employee-card__subheading accentiate-text"
                           onClick={(evt) => evt.stopPropagation()}>{cardLocalization[language].keyCompetencies}</p>
                        <ul className="employee-card__key-competencies-list" onClick={(evt) => evt.stopPropagation()}>
                            {
                                employee.newKeyCompetencies
                                &&
                                parseRichText(employee.newKeyCompetencies, "employee-block__competencies-list-item", "disc", { color: "black"} )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )

    const typeLayoyt = {
        widget: employeeCardWidgetLayoyt,
        gallery: galleryLayout,
    }

    return (
        typeLayoyt[type]
    );
};

export default EmployeeCard;
