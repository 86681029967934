import React from "react";
import { useSelector } from "react-redux";

import Header from "../Header/Header"
import NewsList from "../NewsList/NewsList"
import Footer from "../Footer/Footer";
import {NavLink, useLocation} from "react-router-dom";

const NewsPage = () => {
    const language = useSelector((state) => state.meta.language)

    const pagesState = useSelector((state) => state.pages)
    const newsList = pagesState.news[language]

    function chunkArray(array, chunkSize) {
        let index = 0;
        let arrayLength = array.length;
        let tempArray = [];

        for (index = 0; index < arrayLength; index += chunkSize) {
            let myChunk = array.slice(index, index + chunkSize);
            tempArray.push(myChunk);
        }

        return tempArray;
    }

    const [chunkNews, setChunkNews] = React.useState(null)

    React.useEffect(() => {
        if (!chunkNews) {
            const a = chunkArray(newsList.slice(3, newsList.length), 4)
            setChunkNews(a)
        }
    }, [chunkNews])

    return(
        <>
            <Header page="mainPage" color="white" border="true" style={{ marginBottom: "50px" }} />
            <NewsList news={newsList} type='gallery' />

            {
                chunkNews
                &&
                newsList.length > 3
                &&
                chunkNews.map((news, i) => {
                    return (<NewsList news={news} type="poster" key={i} />)
                })
            }

            <Footer />
        </>
    )
}

export default NewsPage