import "./UI.css"
import React from "react";
import { useSwiper } from "swiper/react";
import Arrow from "../../images/Arrow.svg"
import ArrowReverse from "../../images/arrow-reverse.svg"

const Pagination = ({ currentPage, pagesQuantity, style }) => {
    const swiper = useSwiper()

    function nextSlide() {
        if (swiper) {
            swiper.slideNext()
        }
    }

    function prevSlide() {
        if (swiper) {
            swiper.slidePrev()
        }
    }

    return (
        <div className="pagination" style={ style }>
            <button className="pagination__previous-slide" style={{ backgroundImage: `url(${ ArrowReverse })` }} onClick={prevSlide}></button>
            <button className="pagination__next-slide" style={{ backgroundImage: `url(${ Arrow })` }} onClick={nextSlide}></button>
        </div>
    );
}

export default Pagination;