import "./Promo.css";
import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import Section from "../Section/Section";
import Button from "../UI/Button";
import { contactButtonText } from "../../utils/constants/buttonsText";
import useDeviceType from "../../utils/hooks/useDeviceType";

const Promo = ({ name, type, color, title, subtitle, image = "", imageAlt = "Picture", children, button = false, style, onButton }) => {
    const deviceType = useDeviceType()
    const meta = useSelector((state) => state.meta)
    const language = meta.language

    return (
        <>
            {
                (
                    type === "practice-area"
                    &&
                    deviceType === "Desktop"
                )
                &&
                !image.includes("undefined")
                &&
                <img className={`promo__image promo__image_type_${type}`} src={image} alt={imageAlt} />
            }
            <Section name={name} color={color} style={{ overflow: "hidden", ...style }}>
                <div className="promo__text-container">
                    <h2 className="promo__title">
                        {title}
                    </h2>

                    {
                        subtitle
                        &&
                        <div className="promo__subtitle">
                            {subtitle}
                        </div>
                    }

                    {children}

                    {
                        button === "true"
                        &&
                        <Button type="primary" onClick={onButton}>{contactButtonText[language]}</Button>
                    }
                </div>

                {
                    (
                        type === "main"
                        ||
                        deviceType !== "Desktop"
                    )
                    &&
                    <div className="promo__image-container">
                        <div className="promo__bg-ellipse">
                            {
                                !image.includes("undefined")
                                &&
                                <img className={`promo__image promo__image promo__image_type_${type}`} src={image} alt={imageAlt} />
                            }
                        </div>
                    </div>
                }
            </Section>
        </>
    )
}

export default Promo;
