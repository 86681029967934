import "./Section.css";
import React from "react";

const Section = ({
    name,
    type = "default",
    title = " ",
    color,
    style,
    children,
    sliderPagination = {}
}) => {
    const defaultTitleLayout = (
        <h2 className="section__title">{title.toUpperCase()}</h2>
    )

    const sliderTitleLayout = (
        <></>
    )

    const sectionTitleVariation = {
        default: defaultTitleLayout,
        slider: sliderTitleLayout,
    }

    const sectionColorVariations = {
        white: "section_color_white",
        gray: "section_color_gray",
    }

    return (
        <section className={`section section_${name} ${sectionColorVariations[color]}`} style={style}>
            <div className={`section__container section__container_${name}`}>
                {
                    type
                    &&
                    sectionTitleVariation[type]
                }
                {children}
            </div>
        </section>
    )
}

export default Section;
