import {normalizeEmployee} from "./normalizeEmployee";

export function extractLocalization(dataObject, type = "array", handler = (elem) => elem) {
    const localizatedData = {
        ru: [],
        en: [],
        kz: [],
    }

    if (type === "array") {
        dataObject.forEach((obj) => {
            if (obj.attributes.locale === "ru-RU") {
                localizatedData.ru.push({ ...handler(obj.attributes) })
            } else if (obj.attributes.locale === "en") {
                localizatedData.en.push({ ...handler(obj.attributes) })
            } else if (obj.attributes.locale === "kk") {
                localizatedData.kz.push({ ...handler(obj.attributes) })
            }
        })
    } else if (type === "object") {
        dataObject.forEach((obj) => {
            if (obj.attributes.locale === "ru-RU") {
                localizatedData.ru = handler(obj.attributes)
            } else if (obj.attributes.locale === "en") {
                localizatedData.en = handler(obj.attributes)
            } else if (obj.attributes.locale === "kk") {
                localizatedData.kz = handler(obj.attributes)
            }
        })
    }

    return localizatedData
}