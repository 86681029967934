import "./Logo.css";
import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../images/logo.svg";

const Logo = () => {
    const local = "Logo of the law firm 'ALC Legal'"

    return(
        <div className="logo">
            <NavLink to="/">
                <img className="logo__picture" src={logo} alt={local} />
            </NavLink>
        </div>
    )
}

export default Logo;
