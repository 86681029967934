import { createSlice } from "@reduxjs/toolkit"
import { extractLocalization } from "../../utils/functions/extractLocalization"
import { normalizeEmployee } from "../../utils/functions/normalizeEmployee"
import { normalizePracticeArea } from "../../utils/functions/normalizePracticeArea"
import { normalizeNews } from "../../utils/functions/normalizeNews"
import { normalizeMainPromo } from "../../utils/functions/normalizeMainPromo"
import { normalizeContacts } from "../../utils/functions/normalizeContacts"

// Дефолтное значение стейта
const initialState = {
    mainPromo: {},
    practiceAreas: [],
    employee: [],
    news: [],
}

export const pagesSlice = createSlice({
    name: "pages",
    initialState,
    reducers: {
        setMainPageState(state, action) {
            const employeeGallery = extractLocalization(action.payload[1].data, "array")
            const employeeRU = employeeGallery?.ru[0]?.employee.data.map((elem) => {
                return normalizeEmployee(elem.attributes)
            })
            const employeeEN = employeeGallery?.en[0]?.employee.data.map((elem) => {
                return normalizeEmployee(elem.attributes)
            })
            const employeeKZ = employeeGallery?.kz[0]?.employee.data.map((elem) => {
                return normalizeEmployee(elem.attributes)
            })

            console.log(employeeGallery)
            employeeGallery.ru = employeeRU
            employeeGallery.en = employeeEN
            employeeGallery.kz = employeeKZ

            return {
                ...state,
                mainPromo: extractLocalization(action.payload[0].data, "object", normalizeMainPromo),
                employee: employeeGallery,
                practiceAreas: extractLocalization(action.payload[2].data, "array", normalizePracticeArea),
                news: extractLocalization(action.payload[3].data, "array", normalizeNews),
                contacts: extractLocalization(action.payload[4].data, "array", normalizeContacts),
                practiceAreasPage: extractLocalization(action.payload[5].data, "array"),
                teamPage: extractLocalization(action.payload[6].data, "array"),
            }
        },

        setMainPromoState(state, action) {
            state.mainPromo = extractLocalization(action.payload[0].data, "object")
        },
        setEmployeeState(state, action) {
            state.employee = extractLocalization(action.payload[0].data, "array", normalizeEmployee)
        },
        setPracticeAreasState(state, action) {
            state.practiceAreas = extractLocalization(action.payload[0].data, "array", normalizePracticeArea)
        },
        setNewsState(state, action) {
            state.news = extractLocalization(action.payload[0].data, "array", normalizeNews)
        }
    }
})

export const { setMainPageState, setMainPromoState, setEmployeeState, setPracticeAreasState, setNewsState } = pagesSlice.actions;
export default pagesSlice.reducer;
