import mainApi from "../../utils/api/Api"
import { put, takeEvery, call, takeLatest } from "redux-saga/effects"
import { setEmployeeState, setMainPageState, setMainPromoState, setNewsState, setPracticeAreasState } from "../pages/pagesSlice"
import { setIsLoading } from "../meta/metaSlice"

const mainPagePromises = [mainApi.getMainPromo(), mainApi.getEmployees(), mainApi.getPracticeAreas(), mainApi.getNews(), mainApi.getContactsPage(), mainApi.getPracticeAreasPage(), mainApi.getTeamPage()]
const practiceAreaPagePromises = [mainApi.getPracticeAreas(), mainApi.getNews()]
const getPage = (promises) => Promise.all(promises)

function* getMainPage() {
    yield put(setIsLoading(true))

    const response = yield call(getPage, mainPagePromises)
    yield put(setMainPageState(response))

    yield put(setIsLoading(false))
}

function* getPracticeAreaPage() {
    yield put(setIsLoading(true))

    const response = yield call(getPage, practiceAreaPagePromises)
    yield put(setIsLoading(response))

    yield put(setIsLoading(false))
}

function* getMainPromo() {
    yield put(setIsLoading(true))

    const response = yield call(getPage, [mainApi.getMainPromo()])
    yield put(setMainPromoState(response))

    yield put(setIsLoading(false))
}

function* getEmployees() {
    yield put(setIsLoading(true))

    const response = yield call(getPage, [mainApi.getEmployees()])
    yield put(setEmployeeState(response))

    yield put(setIsLoading(false))
}

function* getPracticeAreas() {
    yield put(setIsLoading(true))

    const response = yield call(getPage, [mainApi.getPracticeAreas()])
    yield put(setPracticeAreasState(response))

    yield put(setIsLoading(false))
}

function* getNews() {
    yield put(setIsLoading(true))

    const response = yield call(getPage, [mainApi.getNews()])
    yield put(setNewsState(response))

    yield put(setIsLoading(false))
}

export function* stateWatcher() {
    yield takeEvery("GET-MAIN-PAGE", getMainPage)
    yield takeEvery("GET-PRACTICE-AREA-PAGE", getPracticeAreaPage)
    yield takeEvery("GET-MAIN-PROMO", getMainPromo)
    yield takeEvery("GET-EMPLOYEES", getEmployees)
    yield takeEvery("GET-PRACTICE-AREAS", getPracticeAreas)
    yield takeEvery("GET-NEWS", getNews)
}