import React from "react"
import { useSelector } from "react-redux/es/hooks/useSelector"

const Loader = ({element: Component, ...props}) => {
    const isLoading = useSelector((state) => state.meta.isLoading)

    return (
        <>
            {
                isLoading
                ?
                <></>
                :
                <>
                    <Component {...props} />
                </>
            }
        </>
    )
}

export default Loader
