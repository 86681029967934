import './JoinUsForm.css';
import React from 'react';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import Form from '../Form/Form';
import PersonIcon from "../../images/icon-join-us.svg";
import Input from '../UI/Input';
import mainApi from '../../utils/api/Api';
import iconNext from "../../images/icon-next.svg"
import { notification } from 'antd';

const JoinUsForm = () => {
    const [api, contextHolder] = notification.useNotification();
    const [isSent, setIsSent] = React.useState(null)

    React.useEffect(() => {
        if(isSent) {
            api.open({
                message: "Уведомление",
                description: "Ваше резюме успешно отправлено! Мы свяжемся с вами в ближайшее время.",
                duration: 0,
            })
        } else if (isSent === false) {
            api.open({
                message: "Уведомление",
                description: "Ошибка! Попробуйте еще раз.",
                duration: 0,
            })
        }
    }, [isSent])

    const [selectedFile, setSelectedFile] = React.useState(null)
    const [inputFileValue, setInputFileValue] = React.useState("")
    const inputFileRef = React.useRef(null)

    const [emailInput, setEmailInput] = React.useState("")

    const language = useSelector((state) => state.meta.language)
    const titleVariations = {
        ru: "Присоединиться к команде",
        en: "Join our team",
        kz: "Біздің командаға қосылыңыз"
    }
    const inputEmailPlaceholder = {
        ru: "Ваш email",
        en: "Your email",
        kz: "Сіздің email",
    }
    const inputFilePlaceholder = {
        ru: "Ваше резюме",
        en: "Your resume",
        kz: "Сіздің түйіндемеңіз",
    }

    function handleFileClick() {
        inputFileRef.current.click()
    }

    function handleFileChange(evt) {
        setSelectedFile(evt.target.files[0])
        setInputFileValue(evt.target?.files[0]?.name)
    }


    let sentCounter = 0
    const allowedExtensions = ["pdf", "doc", "docx", "jpeg"]
    function sendApplication() {
        if (sentCounter >= 1) return
        sentCounter++

        const formData = new FormData()
        formData.append("files", selectedFile)

        const extension = selectedFile.name.replace(/.+\./gm, "")
        if (!allowedExtensions.includes(extension)) {
            setIsSent(false)
            return
        }

        const reqBody = { form: formData, email: emailInput }

        mainApi.sendApplication(reqBody)
            .then(() => {
                setIsSent(true)
            })
            .catch((err) => {
                setIsSent(false)
                sentCounter = 0
                console.log(`Ошибка! Код: ${err}`)
            })
    }

    return (
        <Form title={titleVariations[language]} icon={PersonIcon} type='joinUs' place='block' text={[]} onSubmit={sendApplication}>
            {contextHolder}
            <fieldset className="form__fieldset form__fieldset_type_join-us">
                <div className="form__input-container form__input-container_type_join-us">
                    <Input type="email" isReqired defaultValue="" placeholder={inputEmailPlaceholder[language]} setEmailInput={setEmailInput} />
                </div>

                <div className="form__input-container form__input-container_type_join-us">
                    <div className="fake-input" onClick={handleFileClick}>{ inputFileValue || inputFilePlaceholder[language] }</div>
                    {/* <Input type="file" isReqired value={inputFileValue} defaultValue="" placeholder={inputFilePlaceholder[language]} onClick={handleFileClick} disabled /> */}
                    <input className="hidden" type="file" ref={inputFileRef} onChange={handleFileChange} />
                </div>

                <button style={{ backgroundImage: `url(${iconNext})`}} className='form__btn-next' onClick={sendApplication} disabled={isSent}></button>
            </fieldset>
        </Form>
    );
}

export default JoinUsForm;
