import "./EmployeeBlock.css";
import ReactMarkdown from "react-markdown"
import { useSelector } from "react-redux";
import parseRichText from "../../utils/functions/parseRichText";

const EmployeeBlock = ({ employee: { name, description, position, city, about, newKeyCompetencies, education, avatar }, style, children }) => {
    const meta = useSelector((store) => store.meta)
    const language = meta.language

    const competenciesLocalization = {
        ru: "Ключевые компетенции",
        en: "Key competencies",
        kz: "Негізгі құзыреттер",
    }

    const educationLocalization = {
        ru: "Образование",
        en: "Education",
        kz: "Білім беру"
    }

    return (
        <section className="employee-block" style={style}>
            { children }
            <div className="employee-block__image-container">
                <img className="employee-block__image" src={avatar} alt={name} />
            </div>

            <div className="employee-block__text-container">
                <h2 className="employee-block__name">{name}</h2>

                <ul className="employee-block__credentials-list">
                    <li className="employee-block__credentials-item employee-block__credentials-item_position">{position}</li>
                    <li className="employee-block__credentials-item employee-block__credentials-item_city">{city}</li>
                </ul>

                {about && <p className="employee-block__about">{about}</p>}

                <div className="employee-block__competencies-container">
                    <div>
                        <h4 className="employee-block__competencies-heading">{ competenciesLocalization[language] }</h4>
                        <ul className="employee-block__competencies-list">
                            {
                                newKeyCompetencies
                                &&
                                parseRichText(newKeyCompetencies, "employee-block__competencies-list-item", true, { color: "black" })
                            }
                        </ul>
                    </div>

                    <div>
                        <h4 className="employee-block__competencies-heading">{ educationLocalization[language] }</h4>
                        <ul className="employee-block__competencies-list employee-block__competencies-list_education">
                            <li className="employee-block__competencies-list-item">{education}</li>
                        </ul>
                    </div>
                </div>

                <p className="employee-block__description">
                    <ReactMarkdown>{ description }</ReactMarkdown>
                </p>
            </div>
        </section>
    );
}

export default EmployeeBlock;