import { publicGalleryURL } from "../constants/webConfig"

export function normalizeMainPromo (mainPromo) {
    if(!mainPromo) return

    return {
        ...mainPromo,
        image: mainPromo.image && `${publicGalleryURL}${mainPromo.image?.data?.attributes?.url}`,
        advBlock: {
            ...mainPromo.advBlock,
            image: `${publicGalleryURL}${mainPromo.advBlock?.image?.data?.attributes?.url}`
        }
    }
}
